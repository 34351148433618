import React from 'react';
import Title from "../Title";
import { string, func, bool, oneOf } from "prop-types";
import CtaPrimary from "../Buttons/Button";
import {StaticImage} from "gatsby-plugin-image";
const HeadingDirective = (props) => {
    const {directive, symbol, subtext, className, link, btntitle, showCircle, imgClass} = props
    return (
        <div className='relative'>
            {showCircle && <StaticImage style={{ position: "absolute" }} className={`absolute right-0 lg:top-[147px] 2xl:w-[333px] lg:w-[250px] block z-0 ${imgClass}`} src='../../assets/new-circle.png' alt='detail' />}
            <div className={`${className} lg:pl-[160px] lg:pr-64 pr-[16px] md:pl-8 pl-[16px] md:pt-[160px] md:pb-[100px] pt-12 px-6 md:mr-48 sm:mr-0 max-w-[1440px] lg:mx-auto relative`}>
                <Title level={1} withBlueSymbol symbol={symbol} className='text-black mt-0 lg:max-w-[926px] max-w-auto text-[42px] lg:text-[72px] leading-[140%] lg:leading-[110%]'>{directive}</Title>
                <p className='font-sans text-black-200 max-w-[962px] text-[20px] leading-[160%] mt-0'>{subtext}</p>
                {link && (
                    <CtaPrimary
                        title={btntitle}
                        target={link}
                        className='md:mb-0 mb-[103px]'
                    />
                )}
            </div>
        </div>
    );
};

HeadingDirective.propTypes = {
    className: string,
    imgClass: string,
    directive: string,
    symbol: string,
    subtext: string,
    link: string,
    btntitle: string,
    showCircle: bool,
}
HeadingDirective.defaultProps = {
    className: '',
    imgClass: '',
    directive: '',
    symbol: '',
    subtext: '',
    link: '',
    btntitle: '',
    showCircle: false
}

export default HeadingDirective;
