import React from "react";
import {Link, navigate} from "gatsby";
import { string, func, bool, oneOf } from "prop-types";
import chevron from "../../images/svg/chevron_left.svg";

const CtaPrimary = ({ className, title, onClick, target, large, openInNewTab }) => {
  const Chevron = <img className="ml-2.5" alt="chevron icon" src={chevron} />;
  const styles = `
        ${className} 
        cta-p
        flex flex-row items-center
        px-6 py-2.5
        rounded-full border-0 cursor-pointer
        text-white font-bold bg-primary hover:bg-opacity-70
        ${large ? "text-cta-large" : "text-cta"}
        transition-all duration-200
      `;
  const urlOpener = () => {
    if (openInNewTab) {
      return window.open(target, "_blank").focus();
    }
    return navigate(target);
  };

  const clickHandler = target ? urlOpener : onClick;
  if (target && !openInNewTab) {
    return (
      <Link
        className={`${styles} hover:text-white w-max font-sans no-underline`}
        to={target}
      >
        {title}
        {Chevron}
      </Link>
    );
  }
  return (
    <button className={styles} onClick={clickHandler} type="button">
      {title}
      {Chevron}
    </button>
  );
};

CtaPrimary.propTypes = {
  className: string,
  title: string.isRequired,
  onClick: func,
  target: string,
  large: bool,
};

CtaPrimary.defaultProps = {
  className: "",
  onClick: () => true,
  target: null,
  large: false,
};

export default CtaPrimary;
